import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";
import {useMemo} from "react";
import {DealTypes} from "@deals/constants";
import {mapPromotion, mapReward} from "@deals/utils";
import Promotion from "src/core/common/models/promotion";
import Reward from "src/core/common/models/reward";
import useRouter from "src/core/common/hooks/useRouter";

export default function usePrefetchedDeals() {
  const {data} = useServerContext();
  const {shop} = useRouter().query;
  const prefetchedDeals = data?.[PrefetchedDataKeys.DEALS]?.[shop];

  const deals = useMemo(() => {
    if (!prefetchedDeals) return null;
    return prefetchedDeals.map(prefetchedDeal => {
      if (prefetchedDeal.type === DealTypes.PROMOTIONS) {
        return mapPromotion(new Promotion(prefetchedDeal));
      } else {
        return mapReward(new Reward(prefetchedDeal));
      }
    });
  }, [prefetchedDeals]);

  if (!deals)
    return {
      prefetchedDeals: null,
      prefetchedMeta: {},
    };

  return {
    prefetchedDeals: deals,
    prefetchedMeta: {
      noResults: false,
      loading: false,
      hasMore: false,
    },
  };
}
