import TymberApp from "src/apps/common/tymber";
import AppProvider from "../online/AppProvider";
import KioskAppProvider from "src/apps/kiosk/KioskAppProvider";
import {getEnv} from "src/server/utils/getEnv";

export function makeTymberApp() {
  return new TymberApp();
}

export function makeAppProvider(shop, host) {
  const env = getEnv(shop, host);
  if (env?.REACT_APP_MODE === "kiosk") {
    return KioskAppProvider;
  }

  return AppProvider;
}
