import React, {useEffect, useState} from "react";
import * as StoresApi from "src/core/api/shops";
import useRouter from "src/core/common/hooks/useRouter";
import BannerPlacement from "src/integrations/surfside/adPlacements/BannerPlacement";

export default function useAdBanner(place) {
  const router = useRouter();
  const [adPlacements, setAdPlacements] = useAdPlacements();
  const [adIntegration, setAdIntegration] = useState(null);
  const [AdComponent, setAdComponent] = useState(null);

  const currentUrl = router.query.from || router.pathname;

  useEffect(() => {
    StoresApi.siteIntegrations().then(response => {
      const surfside = response.data.find(
        service => service.attributes.service === "surfside"
      );

      if (surfside?.attributes?.surfside_ad_banners_active) {
        setAdPlacements(surfside.attributes);
      }
      setAdIntegration(surfside);
    });
  }, []);

  useEffect(() => {
    if (adIntegration) {
      setAdComponent(
        <BannerPlacement
          accountId={adIntegration.attributes.key_1}
          sourceId={adIntegration.attributes.key_2}
          place={getPlace(currentUrl, place)}
          placementId={getPlacementId(adPlacements, currentUrl, place)}
        />
      );
    }
  }, [adIntegration, place, currentUrl]);

  return {
    showAdBanner: getShowAdBanner(adPlacements, currentUrl, place),
    AdComponent,
  };
}

function useAdPlacements() {
  const [adPlacements, setAdPlacements] = useState({
    home_page: {active: false, placementId: null},
    brand_page: {active: false, placementId: null},
    category_page: {active: false, placementId: null},
    cart: {active: false, placementId: null},
  });

  function updateAdPlacements(surfsideAttributes) {
    const adPlacements = ["home_page", "brand_page", "category_page", "cart"].reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          active: surfsideAttributes[`surfside_ad_banners_${key}_active`],
          placementId: surfsideAttributes[`surfside_ad_banners_${key}_placement_id`],
        },
      }),
      {}
    );
    setAdPlacements(adPlacements);
  }

  return [adPlacements, updateAdPlacements];
}

const getPlace = (currentPath, place) => {
  if (place === "cart") return "cart";
  if (currentPath === "/[shop]") return "homepage";
  if (currentPath.includes("/brands")) return "brand-page";
  if (currentPath.includes("/categories")) return "category-page";
  return null;
};

const getPlacementId = (adPlacements, currentPath, place) => {
  const resolvedPlace = PLACES[getPlace(currentPath, place)];
  return adPlacements[resolvedPlace]?.placementId;
};

const getShowAdBanner = (adPlacements, currentPath, place) => {
  const resolvedPlace = PLACES[getPlace(currentPath, place)];
  return adPlacements[resolvedPlace]?.active;
};

const PLACES = {
  homepage: "home_page",
  "brand-page": "brand_page",
  "category-page": "category_page",
  cart: "cart",
};
