import React, {useEffect, useMemo, useState} from "react";
import "src/index.css";
import GlobalStyles from "src/core/common/components/GlobalStyles";
import dynamic from "next/dynamic";
import {SiteHead} from "src/core/seo/components/Head";
import {ModalContextProvider} from "src/core/common/components/modules/modal/modalContext";
import {makeAppProvider} from "src/apps/common/appFactory";
import {logModuleLoadError} from "src/core/common/utils";
import Dialog from "src/core/notifications/components/Dialog";
import SmartBanner from "src/integrations/mobileApp/SmartBanner";
import useScrollRestoration from "src/core/common/hooks/useScrollRestoration";
import {useHistoryManager} from "src/core/common/hooks/useHistoryManager";
import {getSitePreloadData} from "src/server/preloader/site";

const AgeGate = dynamic(
  () =>
    import("src/core/user/components/AgeGate").catch(
      logModuleLoadError("AgeGate", {critical: true})
    ),
  {ssr: false}
);

const MobileAppDownload = dynamic(
  () =>
    import("src/integrations/mobileApp/MobileAppDownload").catch(
      logModuleLoadError("MobileAppDownload", {critical: true})
    ),
  {ssr: false}
);

const IdentityVerificationMain = dynamic(() =>
  import(
    "src/integrations/identityVerification/components/IdentityVerificationMain"
  ).catch(logModuleLoadError("IdentityVerificationMain", {critical: true}))
);

const Toast = dynamic(() =>
  import("src/core/notifications/components/Toast").catch(
    logModuleLoadError("Toast", {critical: true})
  )
);

function TymberApp({router, pageProps, initialProps, Component}) {
  const [requiredProps, setRequiredProps] = useState(initialProps?.props || pageProps);

  useOverridePrefetch();

  const {shop} = router.query;

  useEffect(() => {
    getSitePreloadData(shop, window.location.hostname).then(props =>
      setRequiredProps({...requiredProps, ...props})
    );
  }, [shop]);

  if (isErrorPage(router.pathname)) {
    return <Component />;
  }

  if (!requiredProps?.site) {
    console.error("Missing site in requiredProps.");
    return <div />;
  }

  return <WorkingApp pageProps={requiredProps} Component={Component} router={router} />;
}

const isErrorPage = pathname => {
  const errorPages = ["maintenance", "404", "500"];
  if (errorPages.some(page => pathname.includes(page))) {
    return true;
  }
};

function WorkingApp({Component, pageProps, router}) {
  const {shop, host, siteGroupName, site} = pageProps;
  const AppProvider = makeAppProvider(shop, host);

  useScrollRestoration(router);
  useHistoryManager(router);

  useEffect(() => {
    import("src/serviceWorker").then(mod => {
      void mod.unregisterAll();
    });
  }, []);

  const _host = useMemo(
    () => ({
      host: host?.split(":")[0],
    }),
    [host]
  );

  if (!site) return <Component {...pageProps} />;

  return (
    <AppProvider siteGroupName={siteGroupName} host={_host} initialSite={site}>
      <SiteHead />
      <GlobalStyles />
      <Toast />
      <Dialog />
      <AgeGate />
      <SmartBanner />
      <MobileAppDownload />
      <ModalContextProvider>
        <Component {...pageProps} />
        <IdentityVerificationMain />
      </ModalContextProvider>
    </AppProvider>
  );
}

function useOverridePrefetch() {
  useEffect(() => {
    if (!window.fetch) return;
    const originalFetch = window.fetch;

    window.fetch = async function (...args) {
      const [resource] = args;
      if (resource.includes?.("_next/data")) {
        return new Response(JSON.stringify({}), {
          status: 200,
          headers: {
            "Content-Type": "application/json",
          },
        });
      }

      return originalFetch(...args);
    };
  }, []);
}

export default TymberApp;
