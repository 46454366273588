import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import merge from "lodash/merge";
import media from "@ui/utils/media";
import Checkbox from "@ui/components/Checkbox";

export default function CustomerInfo({customer, order, saveInfo, styles}) {
  const _styles = merge({}, defaultStyles, styles);

  const Icon = order?.deliveryTypeIcon;

  return (
    <Container styles={_styles.root}>
      <InnerContainer styles={_styles.root}>
        <Title styles={_styles.title}>Order information</Title>
        <Info>
          <Column>
            {customer.name && (
              <Cell styles={_styles.info}>
                <span>Name</span>
                <span data-cy="orderDetailCustomerName">{customer.name}</span>
              </Cell>
            )}
            {customer.phoneNumber && (
              <Cell styles={_styles.info}>
                <span>Phone number</span>
                <span data-cy="orderDetailCustomerPhoneNumber">
                  {customer.phoneNumber}
                </span>
              </Cell>
            )}
            {customer.email && (
              <Cell styles={_styles.info}>
                <span>Email</span>
                <span data-cy="orderDetailCustomerEmail">{customer.email}</span>
              </Cell>
            )}
            {customer.stateResidency && (
              <Cell styles={_styles.info}>
                <span>State</span>
                <span data-cy="orderDetailCustomerStateResidency">
                  {customer.stateResidency}
                </span>
              </Cell>
            )}
            <Cell styles={_styles.info}>
              <DeliveryType>{order.deliveryType} address</DeliveryType>
              <Address data-cy="orderDetailDeliveryAddress">
                {order.address.map((addressLine, index) => (
                  <span key={`order-address-line-${index}`}>{addressLine}</span>
                ))}
              </Address>
            </Cell>
          </Column>
          <Column>
            <Cell styles={_styles.info}>
              <Time>Delivery Type</Time>
              <DeliveryTypeLabel styles={_styles.info} data-cy="orderDetailDeliveryType">
                {Icon && <Icon />}
                {order.deliveryTypeLabel}
              </DeliveryTypeLabel>
            </Cell>
            <Cell styles={_styles.info}>
              <Time>{order.deliveryType} time</Time>
              <div data-cy="orderDetailDeliveryTime">{order.orderSpecifications}</div>
            </Cell>
            {order.orderNotes && (
              <Cell styles={_styles.info}>
                <span>Order notes</span>
                <span>{order.orderNotes}</span>
              </Cell>
            )}
          </Column>
        </Info>
      </InnerContainer>
      {saveInfo.show && (
        <SaveCheckoutInfo styles={_styles.saveInfo.root}>
          <Checkbox
            checked={saveInfo.isChecked}
            onClick={saveInfo.onClick}
            styles={_styles.saveInfo.checkbox}
          />
          <span>{saveInfo.label}</span>
        </SaveCheckoutInfo>
      )}
    </Container>
  );
}

const Container = styled.div.attrs(() => ({
  className: "checkout-order-info__customer-info__container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 14px;
  border: ${({styles}) => styles.border};
  border-radius: ${({styles}) => styles.borderRadius};
`;

const InnerContainer = styled.div.attrs(() => ({
  className: "checkout-order-info__customer-info__inner-container",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: ${({styles}) => styles.padding};
`;

const Title = styled.span.attrs(() => ({
  className: "checkout-order-info__customer-info__title",
  "data-keep-cart": "true",
}))`
  font-family: ${({styles}) => styles.fontFamily};
  font-size: ${({styles}) => styles.fontSize.lg};
  font-weight: ${({styles}) => styles.fontWeight};
  color: ${({styles}) => styles.color};

  ${media.down("md")} {
    font-size: ${({styles}) => styles.fontSize.md};
  }
  ${media.down("sm")} {
    font-size: ${({styles}) => styles.fontSize.sm};
  }
`;

const Info = styled.div.attrs(() => ({
  className: "checkout-order-info__customer-info__info",
  "data-keep-cart": "true",
}))`
  display: flex;
  gap: 14px;

  ${media.down("sm")} {
    flex-direction: column;
  }
`;

const Column = styled.div.attrs(() => ({
  className: "checkout-order-info__customer-info__column",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 50%;
`;

const Cell = styled.div.attrs(() => ({
  className: "checkout-order-info__customer-info__cell",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  gap: 6px;

  > :first-child {
    font-family: ${({styles}) => styles.title.fontFamily};
    font-size: ${({styles}) => styles.title.fontSize.lg};
    font-weight: ${({styles}) => styles.title.fontWeight};
    color: ${({styles}) => styles.title.color};

    ${media.down("md")} {
      font-size: ${({styles}) => styles.title.fontSize.md};
    }
    ${media.down("sm")} {
      font-size: ${({styles}) => styles.title.fontSize.sm};
    }
  }

  > :last-child {
    font-family: ${({styles}) => styles.text.fontFamily};
    font-size: ${({styles}) => styles.text.fontSize.lg};
    font-weight: ${({styles}) => styles.text.fontWeight};
    color: ${({styles}) => styles.text.color};

    ${media.down("md")} {
      font-size: ${({styles}) => styles.text.fontSize.md};
    }
    ${media.down("sm")} {
      font-size: ${({styles}) => styles.text.fontSize.sm};
    }
  }
`;

const DeliveryType = styled.span.attrs(() => ({
  className: "checkout-order-info__customer-info__delivery-type",
  "data-keep-cart": "true",
}))`
  text-transform: capitalize;
`;

const Address = styled.div.attrs(() => ({
  className: "checkout-order-info__customer-info__address",
  "data-keep-cart": "true",
}))`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
`;

const Time = styled.span.attrs(() => ({
  className: "checkout-order-info__customer-info__delivery-time",
  "data-keep-cart": "true",
}))`
  text-transform: capitalize;
`;

const DeliveryTypeLabel = styled.div.attrs(() => ({
  className: "checkout-order-info__customer-info__delivery-type-label",
  "data-keep-cart": "true",
}))`
  display: flex;
  gap: 8px;

  > svg > path {
    fill: ${({styles}) => styles.text.color};
  }
`;

const SaveCheckoutInfo = styled.div.attrs(() => ({
  className: "checkout-order-info__customer-info__save-checkout-info",
  "data-keep-cart": "true",
}))`
  display: flex;
  align-items: center;
  gap: 10px;
  border-top: ${({styles}) => styles.border};
  border-bottom-left-radius: ${({styles}) => styles.borderRadius};
  border-bottom-right-radius: ${({styles}) => styles.borderRadius};
  background-color: ${({styles}) => styles.backgroundColor};
  padding: ${({styles}) => styles.padding};

  > span {
    font-family: ${({styles}) => styles.fontFamily};
    font-size: ${({styles}) => styles.fontSize.lg};
    font-weight: ${({styles}) => styles.fontWeight};
    color: ${({styles}) => styles.color};

    ${media.down("md")} {
      font-size: ${({styles}) => styles.fontSize.md};
    }
    ${media.down("sm")} {
      font-size: ${({styles}) => styles.fontSize.sm};
    }
  }
`;

const defaultStyles = {
  root: {
    border: "1px solid #D9D9D9",
    borderRadius: "5px",
    padding: "14px",
  },
  title: {
    fontFamily: "sans-serif",
    fontSize: {
      lg: "18px",
      md: "18px",
      sm: "18px",
    },
    fontWeight: 600,
    color: "#333333",
  },
  info: {
    title: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      fontWeight: 400,
      color: "#000",
    },
    text: {
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      fontWeight: 400,
      color: "#545454",
    },
  },
  saveInfo: {
    root: {
      padding: "14px",
      border: "1px solid #D9D9D9",
      borderRadius: "5px",
      backgroundColor: "#F5F5F5",
      fontFamily: "sans-serif",
      fontSize: {
        lg: "14px",
        md: "14px",
        sm: "14px",
      },
      fontWeight: 400,
      color: "#545454",
    },
    checkbox: {
      root: {
        width: "18px",
        height: "18px",
        margin: "0",
        borderColor: "#D9D9D9",
        borderRadius: "2px",
      },
    },
  },
};

CustomerInfo.defaultProps = {
  customer: {
    name: "",
    phoneNumber: "",
  },
  order: {
    deliveryType: "",
    address: [],
    orderSpecifications: {
      type: "",
      time: "",
    },
  },
  saveInfo: {
    show: false,
    label: "Save my information for a next purchase",
  },
  showEmail: PropTypes.bool,
  styles: defaultStyles,
};

CustomerInfo.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
  }),
  order: PropTypes.shape({
    deliveryType: PropTypes.string,
    address: PropTypes.arrayOf(PropTypes.string),
    orderSpecifications: PropTypes.string,
    orderNotes: PropTypes.string,
  }),
  saveInfo: PropTypes.shape({
    show: PropTypes.bool,
    label: PropTypes.string,
    isChecked: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  styles: PropTypes.shape({
    root: PropTypes.shape({}),
  }),
};
