import EventBus from "src/core/common/eventBus";
import TymberAPIConfig from "src/core/api/config";

class OnlinePayment {
  constructor(code) {
    this._code = code;
    this.eventBus = new EventBus();
  }

  getConfiguration() {
    throw new Error("Not Implemented");
  }

  preparePayment(orderInfo) {
    throw new Error("Not Implemented");
  }

  maybeAttachTip(orderPayload, paymentData) {
    if (paymentData?.tip?.amount > 0) {
      orderPayload.payment_specification.tip = {
        value: paymentData.tip.amount,
        type: "$",
      };
    }

    return orderPayload;
  }

  async maybeHandle(info, e) {
    throw e;
  }

  isOnline() {
    return true;
  }

  get code() {
    return this._code;
  }

  async getPaymentDisclaimer() {
    return "";
  }

  async addTip() {
    throw new Error("tip not supported for " + this.code);
  }

  subscribe(handler) {
    return this.eventBus.subscribe(handler);
  }

  notify(event, payload) {
    return this.eventBus.notify({event, data: payload});
  }

  maybeAttachFailPayment(result) {
    if (window.failPayment) {
      if (!result.payment_specification) {
        result.payment_specification = {};
      }
      result.payment_specification.fail_code =
        typeof window.failPayment !== "number" ? 3 : window.failPayment;
    }
  }

  isProduction() {
    const axios = TymberAPIConfig.axios();
    return !axios.defaults.baseURL.includes("qa");
  }
}

export default OnlinePayment;
