import DealDetail from "@ui/components/DealDetail";
import React from "react";
import styles from "@themes/default/components/DealDetail/styles";
import useTheme from "@mock/hooks/useTheme";
import styled from "styled-components";

export default function DefaultDealDetail(props) {
  const theme = useTheme();
  const {deal, loading, site, onClickCopyButton} = props;

  return (
    <Container>
      <DealDetail
        skeleton={loading}
        image={deal?.image}
        title={deal?.title}
        description={deal?.description}
        type={deal?.type}
        disclaimer={deal?.disclaimer}
        promoCodes={deal?.promoCodes}
        category={deal?.category}
        styles={styles(theme, site)}
        onClickCopyButton={onClickCopyButton}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 42px;
`;
