import React from "react";
import {useThemeComponents} from "@mock/hooks/useThemeComponents";
import DetailProductsList from "@themes/default/components/DetailProductsList";
import useDealDetail from "@mock/hooks/useDealDetail";
import BackButton from "@themes/default/components/BackButton";
import EmptyPlaceholder from "@ui/components/EmptyPlaceholder";
import {primaryButtonStyles} from "@themes/default/components/Button/styles";
import useTheme from "@mock/hooks/useTheme";
import SeoHead from "@mock/components/modules/SeoHead";
import {Button} from "@ui";

export default function DealDetailPage({...props}) {
  const {BasePage, DealDetail} = useThemeComponents();
  const {onBack, products, notFound, backToDealsPage, ...dealDetailProps} =
    useDealDetail();
  const theme = useTheme();

  if (notFound)
    return (
      <BasePage currentPage="deal-detail" show {...props}>
        <BackButton onClick={onBack} />
        <EmptyPlaceholder
          title={"This promotion is no longer available"}
          buttonText={"Check our other promotions"}
          ActionsComponent={<Button styles={primaryButtonStyles(theme)} />}
          action={backToDealsPage}
        />
      </BasePage>
    );

  return (
    <BasePage currentPage="deal-detail" show {...props}>
      <SeoHead deal={dealDetailProps.deal} />
      <BackButton onClick={onBack} />
      <DealDetail {...dealDetailProps} />
      {products?.totalCount > 0 && <DetailProductsList {...products} />}
    </BasePage>
  );
}
