import React from "react";
import InternalLink from "src/core/common/components/modules/InternalLink";

function Link(props) {
  const {
    children,
    keepQuery,
    keepScroll,
    to,
    external,
    openInNewTab,
    staticContext,
    underlined,
    accessibilityLabel,
    className,
    prefetch,
    shallow,
    ...rest
  } = props;

  if (external) {
    return (
      <a
        {...rest}
        className={className}
        href={to ? to.pathname : ""}
        style={underlined ? {textDecoration: "underlined"} : {textDecoration: "none"}}
        aria-label={accessibilityLabel}
        target={openInNewTab && "_blank"}
      >
        {children}
      </a>
    );
  } else {
    return <InternalLink {...props}>{children}</InternalLink>;
  }
}

export default Link;
