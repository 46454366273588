import useSite from "src/core/sites/hooks/useSite";
import useRouter from "src/core/common/hooks/useRouter";
import {useEffect} from "react";
import useDeal from "@deals/hooks/useDeal";
import usePromotionProducts from "@deals/hooks/usePromotionProducts";
import useThemeConfig from "src/themes/useThemeConfig";
import routes from "src/core/common/routes";
import * as Notifications from "src/core/notifications";
import useServerContext from "src/server/hooks/useServerContext";
import {PrefetchedDataKeys} from "src/server/constants";
import merge from "lodash/merge";
import {mapReward, mapPromotion} from "src/deals/utils";
import Promotion from "src/core/common/models/promotion";

export default function useDealDetail() {
  const config = useThemeConfig();
  const site = useSite();
  const {dealType, deal: dealSlug} = useRouter().query;
  const router = useRouter();

  const {
    fetchDeal,
    clearDeal,
    state: {deal, loading, error},
  } = useDeal({dealType, dealId: dealSlug});

  const prefetchedDeal = usePrefetchedDeal(dealSlug);

  const dealToUse = prefetchedDeal?.deal || deal;

  const prefetchedPromotionProducts = usePrefetchedPromotionProducts(dealToUse?.id);

  const {fetchPromotionProducts, state: promotionProductsState} = usePromotionProducts();

  const meta = merge(
    {
      skeleton: promotionProductsState.showSkeleton,
      hasMore: promotionProductsState.hasMore,
      loading: promotionProductsState.loading,
      totalCount: promotionProductsState.totalCount,
    },
    prefetchedPromotionProducts?.meta
  );

  const products =
    prefetchedPromotionProducts?.products || promotionProductsState.products;

  useEffect(() => {
    if (!deal && !prefetchedDeal) fetchDeal(dealType, dealSlug);
  }, [dealType, dealSlug, prefetchedDeal]);

  useEffect(() => {
    if (deal) fetchPromotionProducts(deal.id, {reset: true});
  }, [deal]);

  useEffect(() => {
    return clearDeal;
  }, []);

  return {
    loading: prefetchedDeal?.meta.loading ?? loading,
    site: site,
    deal: dealToUse,
    onBack: router.safeBack,
    backToDealsPage: () => router.push({pathname: routes.deals}),
    onClickCopyButton: code => {
      navigator.clipboard.writeText(code);
      Notifications.info(`Promo code ${code} copied to clipboard!`);
    },
    notFound: error?.status === 404,
    products: {
      site,
      products: products,
      next: fetchPromotionProducts,
      ProductCardComponent: config.components.ProductCard,
      title: "Save with these products",
      description: "",
      ...meta,
    },
  };
}

function usePrefetchedDeal(dealSlug) {
  const {data} = useServerContext();

  const prefetchedDeal =
    dealSlug && data[PrefetchedDataKeys.DEAL]?.slug === dealSlug
      ? data[PrefetchedDataKeys.DEAL]
      : null;

  if (!prefetchedDeal) return null;

  const deal =
    prefetchedDeal instanceof Promotion
      ? mapPromotion(prefetchedDeal)
      : mapReward(prefetchedDeal);

  return {
    deal,
    meta: {
      error: null,
      loading: false,
    },
  };
}

function usePrefetchedPromotionProducts(dealId) {
  const {data} = useServerContext();

  const prefetchedPromotionProducts =
    dealId && data[PrefetchedDataKeys.PROMOTION_PRODUCTS]?.params?.promotion === dealId
      ? data[PrefetchedDataKeys.PROMOTION_PRODUCTS]?.data
      : null;

  if (!prefetchedPromotionProducts) {
    return null;
  }

  const meta = prefetchedPromotionProducts?.meta;

  return {
    products: prefetchedPromotionProducts?.objects,
    meta: {
      skeleton: false,
      hasMore: meta?.total_count > meta?.limit + meta?.offset,
      loading: false,
      totalCount: meta?.total_count,
    },
  };
}
